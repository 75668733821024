




















import Vue from 'vue';
import { Component, Inject } from 'vue-property-decorator';

import { FilterInterface, CustomFilter, FsCanvas, CANVAS_EVENTS, FilterPayload, FilterType } from '@filestack/canvas-sdk';
import { Getter } from 'vuex-class';

const THUMB_SIZE = { width: 85, height: 85 };

@Component
class EnhanceMenu extends Vue {



  private get activePreset(): string {
    const active = this.canvas.enhance.getActive();

    return active;
  }

  private get enabled() {
    return this.config('editor.filters.enabled');
  }
  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  private urlCache: any = {};

  private presets: string[] = [];

  public isThumbLoaded(preset: string) {
    return this.urlCache && this.urlCache[preset];
  }

  private displayName(preset: string): string {
    return preset;
  }

  private isActive(name: string) {
    return true;
  }

  private async created() {
    for (const name of this.canvas.enhance.getPresets()) {
      this.presets.push(name);
    }
    this.generateThumbBase();
    this.regeneratePresets();
  }

  private mounted() {
    this.regeneratePresets();
    this.canvas.on(CANVAS_EVENTS.UNDO, this.regeneratePresets);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.UNDO, this.regeneratePresets);
  }

  private regeneratePresets() {
    this.generateThumbBase().then(() => {
      this.generateThumbUrl(); // generates thumb without filter
      this.canvas.enhance.getPresets().forEach((name: string) => setTimeout(() => {
        this.generateThumbUrl(name);
      }));
    });
  }

  private async generateThumbBase(): Promise<any> {
    return this.canvas.enhance.init({
      width: THUMB_SIZE.width,
      height: THUMB_SIZE.height,
    });
  }

  private activatePreset(preset: string) {
    if (this.isThumbLoaded(preset)) {
      this.canvas.enhance.setPreset(preset);
    }
  }

  private clearPresets() {
    this.canvas.enhance.clearPresets();
  }

  private async generateThumbUrl(preset: string = ''): Promise<string> {

    if (this.urlCache[preset]) {
      return Promise.resolve(this.urlCache[preset]);
    }

    return this.canvas.enhance.generateThumbUrl(preset).then((url) => {
      this.$set(this.urlCache, preset, url);
      return url;
    });
  }
}

export default EnhanceMenu;
