export const authorizeUrl = (baseUrl: string, policy: string, signature: string): string => {
  const url = new URL(baseUrl);
  const searchParams = new URLSearchParams({policy, signature});

  for (const [key, value] of searchParams) {
    url.searchParams.append(key, value);
  }

  return url.toString();
};
