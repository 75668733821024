// Main components
import AdBlur from '@/views/adjustments/Blur.vue';
import AdBrightness from '@/views/adjustments/Brightness.vue';
import AdContrast from '@/views/adjustments/Contrast.vue';
import AdGamma from '@/views/adjustments/Gamma.vue';
import AdHue from '@/views/adjustments/Hue.vue';
import AdNoise from '@/views/adjustments/Noise.vue';
import AdPixelate from '@/views/adjustments/Pixelate.vue';
import AdSaturation from '@/views/adjustments/Saturation.vue';
import Border from '@/views/Border.vue';
import BottomBarMenu from '@/views/BottomBarMenu.vue';
import FiltersMenu from '@/views/FiltersMenu.vue';
import EnhanceMenu from '@/views/EnhanceMenu.vue';
import Main from '@/views/Main.vue';
import Nav from '@/views/Nav.vue';
import OverlayBottom from '@/views/OverlayBottom.vue';
import TextTool from '@/views/TextTool.vue';
import TopBar from '@/views/TopBar.vue';
import TopLayerMenu from '@/views/TopLayerMenu.vue';
import TransformsCrop from '@/views/transforms/Crop.vue';
import FlipAndRotate from '@/views/transforms/FlipAndRotate.vue';
import TransformsResize from '@/views/transforms/Resize.vue';

export const routes = [
  {
    path: '/',
    name: 'main',
    components: {
      default: Main,
      topBar: TopBar,
      bottomBar: Nav,
    },
    children: [
      {
        path: '/',
        name: 'default',
        components: {
          topBar: TopBar,
          bottomBar: Nav,
        },
      }, {
        path: '/transforms',
        name: 'transforms',
        meta: {
          enabledEntry: 'editor.transforms.enabled',
          title: 'transforms',
          icon: 'crop',
          bottomBarMenu: 'transforms',
        },
        components: {
          topBar: TopBar,
          bottomBar: Nav,
          bottomMenu: BottomBarMenu,
        },
        children: [
          {
            path: '/crop',
            name: 'crop',
            meta: {
              enabledEntry: {
                path: 'editor.transforms.enabled',
                item: 'crop',
              },
              title: 'crop',
              icon: 'crop_on',
            },
            components: {
              bottomMenu: TransformsCrop,
            },
          },
          {
            path: '/resize',
            name: 'resize',
            meta: {
              enabledEntry: {
                path: 'editor.transforms.enabled',
                item: 'resize',
              },
              title: 'resize',
              icon: 'resize_on',
            },
            components: {
              bottomMenu: TransformsResize,
            },
          },
          {
            path: '/flip-rotate',
            name: 'fliprotate',
            meta: {
              enabledEntry: {
                path: 'editor.transforms.enabled',
                item: 'fliprotate',
              },
              title: 'fliprotate',
              icon: 'resize_on',
            },
            components: {
              bottomMenu: FlipAndRotate,
            },
          },
        ],
      },
      {
        path: '/filters',
        name: 'filters',
        meta: {
          enabledEntry: 'editor.filters.enabled',
          title: 'filters',
          icon: 'filter',
        },
        components: {
          topBar: TopBar,
          bottomBar: Nav,
          bottomMenu: FiltersMenu,
        },
      },
      {
        path: '/enhance',
        name: 'enhance',
        meta: {
          enabledEntry: 'editor.enhancements.enabled',
          title: 'enhance',
          icon: 'auto_awesome',
        },
        components: {
          topBar: TopBar,
          bottomBar: Nav,
          bottomMenu: EnhanceMenu,
        },
      },
      {
        path: '/adjustments',
        name: 'adjustments',
        meta: {
          enabledEntry: 'editor.adjustments.enabled',
          title: 'adjustments',
          icon: 'tune',
          bottomBarMenu: 'adjustments',
        },
        components: {
          topBar: TopBar,
          bottomBar: Nav,
          bottomMenu: BottomBarMenu,
        },
        // redirect: { name: 'blur' },
        children: [
          {
            path: '/blur',
            name: 'blur',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'blur',
              },
              title: 'blur',
              icon: 'blur_on',
            },
            components: {
              bottomMenu: AdBlur,
            },
          },
          {
            path: '/brightness',
            name: 'brightness',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'brightness',
              },
              title: 'brightness',
              icon: 'settings_brightness',
            },
            components: {
              bottomMenu: AdBrightness,
            },
          },
          {
            path: '/contrast',
            name: 'contrast',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'contrast',
              },
              title: 'contrast',
              icon: '',
              customClass: 'fs-filter-contrast',
            },
            components: {
              bottomMenu: AdContrast,
            },
          },
          {
            path: '/gamma',
            name: 'gamma',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'gamma',
              },
              title: 'gamma',
              icon: 'color_lens',
            },
            components: {
              bottomMenu: AdGamma,
            },
          },
          {
            path: '/hue',
            name: 'hue',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'hue',
              },
              title: 'hue',
              icon: '',
              customClass: 'fs-filter-hue',
            },
            components: {
              bottomMenu: AdHue,
            },
          },
          {
            path: '/noise',
            name: 'noise',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'noise',
              },
              title: 'noise',
              icon: '',
              customClass: 'fs-filter-noise',
            },
            components: {
              bottomMenu: AdNoise,
            },
          },
          {
            path: '/pixelate',
            name: 'pixelate',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'pixelate',
              },
              title: 'pixelate',
              icon: 'apps',
            },
            components: {
              bottomMenu: AdPixelate,
            },
          },
          {
            path: '/saturation',
            name: 'saturation',
            meta: {
              enabledEntry: {
                path: 'editor.adjustments.enabled',
                item: 'saturation',
              },
              title: 'saturation',
              icon: '',
              customClass: 'fs-filter-saturation',
            },
            components: {
              bottomMenu: AdSaturation,
            },
          },
        ],
      },
      {
        path: '/text-tool',
        name: 'text-tool',
        meta: {
          enabledEntry: 'editor.text.enabled',
          title: 'text_tool',
          icon: 'text_fields',
        },
        components: {
          bottomMenu: TextTool,
          topBar: TopBar,
          topMenu: TopLayerMenu,
          bottomBar: Nav,
        },
      },
      {
        path: '/border',
        name: 'border',
        meta: {
          enabledEntry: 'editor.border.enabled',
          title: 'border',
          icon: 'crop_square',
        },
        components: {
          bottomMenu: Border,
          topBar: TopBar,
          bottomBar: Nav,
        },
      },
      {
        path: '/overlay',
        name: 'overlay',
        meta: {
          enabledEntry: 'editor.overlay.enabled',
          title: 'overlay',
          icon: 'library_add',
        },
        components: {
          bottomMenu: OverlayBottom,
          topBar: TopBar,
          topMenu: TopLayerMenu,
          bottomBar: Nav,
        },
      },
    ],
  },
  {
    path: '*',
    components: {
      default: Main,
    },
  },
];
