import { MaskShape, PositionHorizontal, PositionVertical } from '@filestack/canvas-sdk';
import { PrefetchResponse } from 'filestack-js';
import _get from 'lodash.get';
import _merge from 'lodash.merge';
import _set from 'lodash.set';
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';

import { EditorConfig, RootState, ViewMode } from './../types';
import image from './modules/image';
import toast from './modules/toast';
import transform from './modules/transform';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const initialState: RootState = {
  source: undefined,
  viewMode: ViewMode.OVERLAY,
  prefetch: null,
  sdk: null,
  canvas: {
    // allowTouchScrolling: false,
    // preserveObjectStacking: true,
    // enableRetinaScaling: true,
  },
  config: {
    closeOnSave: true,
    client: {},
    lang: 'en',
    imageConfig: {
      minWidth: 100, // @todo add validation for input image
      minHeight: 100, // @todo add validation for input image
      maxWidth: 100, // @todo add validation for input image
      maxHeight: 100, // @todo add validation for input image
      forceTransform: true, // @todo implement force transform
      background: 'transparent', // @todo should we do that or bg will be always transparent ?
    },
    editor: {
      transforms: {
        enabled: true,
        lockRotation: false,
        lockFlip: false,
        // maskCoordinates: {x: 400, y: 400},
        maskPosition: { vertical: PositionVertical.CENTER, horizontal: PositionHorizontal.CENTER },
        ratios: [
          {
            icon: 'crop_free',
            default: true,
            // default: true, // should be used as default ratio?
            name: 'Custom', // The default translation for this ratio
            // pass to mask
            shape: MaskShape.rect, // rect, circle
            // radius: 1, // if circle
            // dimensions: { minWidth: 2000, minHeight: 2000, maxWidth: 3000, maxHeight: 3000 },
            // || { minWidth: 50, minHeight: 40 } || { maxWidth: 50, maxHeight: 40 } Optional resolution
            // lockRatio: false, // Controls whether the ratio is user-editable
            isShow: true,
          },
          {
            icon: 'panorama_fish_eye',
            name: 'Circle', // The default translation for this ratio
            // pass to mask
            shape: MaskShape.circle, // rect, circle
            // radius: 1, // if circle
            // ratio: 19 / 9, // The image aspect ratio (a floating point number) or
            // dimensions: { minWidth: 500, minHeight: 500, maxWidth: 1000, maxHeight: 1000 },
            lockRatio: true, // Controls whether the ratio is user-editable
            isShow: true,
          },
          {
            icon: 'crop_square',
            name: 'Square', // The default translation for this ratio
            // pass to mask
            shape: MaskShape.rect, // rect, circle
            ratio: 9 / 9, // The image aspect ratio (a floating point number) or
            // dimensions: { width: 50, height: 40 }, // || { minWidth: 50, minHeight: 40 } || { maxWidth: 50, maxHeight: 40 } Optional resolution
            lockRatio: true, // Controls whether the ratio is user-editable
            isShow: true,
          },
          {
            icon: 'crop_portrait',
            name: 'Portrait', // The default translation for this ratio
            // pass to mask
            shape: MaskShape.rect, // rect, circle
            ratio: 9 / 16, // The image aspect ratio (a floating point number) or
            // dimensions: { width: 50, height: 40 }, // || { minWidth: 50, minHeight: 40 } || { maxWidth: 50, maxHeight: 40 } Optional resolution
            lockRatio: true, // Controls whether the ratio is user-editable
            isShow: true,  /* It controls the cropping options whether this option would be visible or not. */
          },
          {
            icon: 'crop_16_9',
            name: 'Landscape', // The default translation for this ratio
            // pass to mask
            shape: MaskShape.rect, // rect, circle
            ratio: 16 / 9, // The image aspect ratio (a floating point number) or
            // dimensions: { width: 50, height: 40 }, // || { minWidth: 50, minHeight: 40 } || { maxWidth: 50, maxHeight: 40 } Optional resolution
            lockRatio: true, // Controls whether the ratio is user-editable
            isShow: true,
          },
        ],
      },
      topMenu: {
        lockRotation: false,
        lockFlip: false,
        remove: true,
        layers: true,
      },
      text: {
        enabled: true,
        defaults: {
          // initial text tool values after creating text
          size: 100,
          color: '#000000',
          backgroundColor: 'transparent',
          bold: false,
          italic: false,
          underline: false,
          align: 'left',
          initialText: 'Example text.',
          opacity: 100,
        },
        // font list visible in editor
        fonts: [
          {
            default: true,
            displayName: 'Times New Roman',
            family: 'Times New Roman, Times, serif',
          },
          {
            displayName: 'Georgia',
            family: 'Georgia, serif',
          },
          {
            displayName: 'Palatino Linotype',
            family: 'Palatino Linotype, Book Antiqua, Palatino, serif',
          },
          {
            displayName: 'Arial',
            family: 'Arial, Helvetica, sans-serif',
          },
          {
            displayName: 'Arial Black',
            family: 'Arial Black, Gadget, sans-serif',
          },
          {
            displayName: 'Comic Sans MS',
            family: 'Comic Sans MS, cursive, sans-serif',
          },
          {
            displayName: 'Impact',
            family: 'Impact, Charcoal, sans-serif',
          },
          {
            displayName: 'Lucida Sans',
            family: 'Lucida Sans Unicode, Lucida Grande, sans-serif',
          },
          {
            displayName: 'Tahoma',
            family: 'Tahoma, Geneva, sans-serif',
          },
          {
            displayName: 'Trebuchet MS',
            family: 'Trebuchet MS, Helvetica, sans-serif',
          },
          {
            displayName: 'Courier New',
            family: 'Courier New, Courier, monospace',
          },
          {
            displayName: 'Verdana',
            family: 'Verdana, Geneva, sans-serif',
          },
          {
            displayName: 'Lucida Console',
            family: 'Lucida Console, Monaco, monospace',
          },
          {
            displayName: 'TLobster',
            family: 'Lobster',
            cssUrl: 'https://fonts.googleapis.com/css?family=Lobster&display=swap', // we can also load own custom fonts from ie google api or customer defined url
          },
        ],
      },
      border: {
        enabled: true,
        defaults: {
          width: 30,
          opacity: 50,
          color: '#000',
        },
      },
      overlay: {
        enabled: true,
        defaults: {
          fromSources: ['local_file_system', 'url', 'imagesearch', 'facebook', 'instagram', 'googledrive', 'dropbox'],
        },
      },
      filters: {
        enabled: true,
        // lut: [{
        //   id: 'lut',
        //   displayName: 'LutTest',
        //   url: 'nanan',
        // }],
        // enabled: [
        //   'blackWhite',
        //   'brownie',
        //   'kodachrome',
        //   'polaroid',
        //   'sepia',
        //   'technicolor',
        //   'vintage',
        //   'iceCold',
        //   'vanillaSky',
        //   'blueOcean',
        //   'autumn',
        //   'milkyWay',
        // ],
        // @example
        // custom: [
        //   {
        //     id: 'milkyWay',
        //     displayName: 'Milky way',
        //     matrix: [0, 1.0, 0, 0, 0, 0, 1.0, 0, 0, 0, 0, 0.6, 1.2, 0, 0, 0, 0, 0, 0.9, 0],
        //   },
        // ],
      },
      enhancements: {
        enabled: true,
      },
      adjustments: {
        enabled: true,
        // enabled: [Adjustments.GAMMA, Adjustments.SATURATION, Adjustments.CONTRAST, Adjustments.HUE, Adjustments.NOISE, Adjustments.BLUR, Adjustments.PIXELATE],
      },
    },
    output: {
      format: 'png',
      quality: 1,
      blob: false, // blob or url
      name: null,
    },
  },
};

const modules = {
  image,
  transform,
  toast,
};

const store: StoreOptions<RootState> = {
  state: Object.assign({}, initialState),
  strict: debug,
  modules,
  getters: {
    viewMode: (st: RootState) => st.viewMode,
    config: (st: RootState) => (val: string, defaultValue: any) => {
      return _get(st.config, val, defaultValue);
    },
    canvasConfig: (st: RootState) => st.canvas,
    closeOnSave: (st: RootState) => st.config.closeOnSave,
    source: (st: RootState) => st.source,
    exportFormat: (st: RootState) => st.config.output.format || 'png',
    exportQuality: (st: RootState) => st.config.output.quality || 1,
    isBlocked: (st: RootState) => {
      if (!st.prefetch || !st.prefetch.permissions || !st.prefetch.permissions.transforms_ui || st.prefetch.blocked) {
        return true;
      }

      return false;
    },
  },

  mutations: {
    setViewMode(st: RootState, mode: ViewMode) {
      st.viewMode = mode;
    },
    setPrefetched(st: RootState, prefetch: PrefetchResponse) {
      Vue.set(st, 'prefetch', prefetch);
    },
    setConfig(st: RootState, data: EditorConfig) {
      Vue.set(st, 'config', _merge({}, st.config, data));
    },
    setConfigKey(st: RootState, { key, value }) {
      _set(st.config, key, value);
    },
    setSource(st: RootState, source: string | File | Blob) {
      Vue.set(st, 'source', source);

      if (st.config.output.name) {
        return;
      }

      if (source instanceof File) {
        Vue.set(st.config.output, 'name', source.name);
      }

      if (typeof source === 'string') {
        Vue.set(
          st.config.output,
          'name',
          source
            .split('/')
            .pop()
            .split('#')[0]
            .split('?')[0],
        );
      }
    },
  },
};

export { store };
