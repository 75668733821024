








import Vue from 'vue';
import { Getter, Mutation } from 'vuex-class';
import { Component, Watch, Inject, Ref } from 'vue-property-decorator';
import debounce from 'lodash.debounce';
import { FsCanvas, CanvasConfig, CANVAS_EVENTS, LAYER_TYPES } from '@filestack/canvas-sdk';
import { adjustMenuPosition } from '@/helpers/menu';

const WIDNOW_RESIZE_DEBOUNCE_TIME = 250;

@Component
class Canvas extends Vue {
  private isLoading: boolean = true;

  @Inject('canvas')
  private canvas: FsCanvas;

  @Getter('config')
  private config: any;

  @Ref('canvas-container')
  private canvasRef: HTMLDivElement;

  @Ref('canvas-style-loader')
  private styleLoaderRef: HTMLDivElement;

  @Getter('source')
  private source: string;

  @Getter('canvasConfig')
  private canvasConfig: CanvasConfig;

  @Mutation('image/saveHistoryState')
  private saveHistoryState: (state: any) => any;

  @Mutation('image/setLoading')
  private setLoding: (state: boolean) => any;

  @Watch('source')
  private onSourceChange(source: string): void {
    if (!source || source.length === 0) {
      return;
    }

    this.canvas.enhance.setSource(source);

    this.canvas.renderImage(source).then(() => {
      this.canvas.saveState('sourceChange');
      // only for fs styling
      // @ts-ignore;
      this.canvas.image.set('shadow', '#757575 0px 1px 10px');
      this.canvas.render();
    });
  }

  private mounted() {
    const canvasCfg = this.canvasConfig;

    canvasCfg.width = this.canvasRef.offsetWidth;
    canvasCfg.height = this.canvasRef.offsetHeight;

    this.canvas.setElement('fs-transform-canvas', canvasCfg, 120);
    const elementStyles = window.getComputedStyle(this.styleLoaderRef);

    this.canvas.setSelectionStyle({
      cornerColor: elementStyles.getPropertyValue('background-color'),
      cornerStrokeColor: elementStyles.getPropertyValue('border-top-color'),
      maskColor: elementStyles.getPropertyValue('border-bottom-color'),
    });

    this.canvas.on(CANVAS_EVENTS.MODIFIED, (data: any) => {
      this.saveHistoryState(data);
    });

    this.canvas.on(CANVAS_EVENTS.CANVAS_CLICKED, () => {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push({ path: '/' });
      }
    });

    this.canvas.on(CANVAS_EVENTS.SELECTION_CHANGED, (data: any) => {
      if (this.$router.currentRoute.name !== 'text-tool' && data.layerType === LAYER_TYPES.TEXT) {
        this.$router.push({ name: 'text-tool', params: { selected: data } });
      }

      if (this.$router.currentRoute.name !== 'overlay' && data.layerType === LAYER_TYPES.OVERLAY) {
        this.$router.push({ name: 'overlay', params: { selected: data }});
      }
    });

    this.canvas.on(CANVAS_EVENTS.LOADING, (state: boolean) => {
      this.isLoading = state;
      this.setLoding(state);
    });

    this.onSourceChange(this.source);
    this.bindResize();
  }

  private bindResize() {
    const resizeDebounce = debounce(() => {
      this.canvas.view.setContainerSize({
        width: this.canvasRef.offsetWidth,
        height: this.canvasRef.offsetHeight,
      });

      Vue.nextTick(() => adjustMenuPosition(this.$root.$el));
    }, WIDNOW_RESIZE_DEBOUNCE_TIME);

    window.addEventListener('resize', resizeDebounce);
  }

  private destroyed() {
    this.canvas.off(CANVAS_EVENTS.LOADING);
    this.canvas.off(CANVAS_EVENTS.MODIFIED);
    this.canvas.off(CANVAS_EVENTS.CANVAS_CLICKED);
    this.canvas.off(CANVAS_EVENTS.SELECTION_CHANGED);
  }
}

export default Canvas;
